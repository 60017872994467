@import '@/styles/theme/variables.scss';

.headingBase {
  margin-bottom: $token-spacing-3;
  text-transform: none;
  font-size: $fullPageBanner-h2-font-size;
  font-weight: $fullPageBanner-h2-font-weight;
  line-height: $fullPageBanner-h2-line-height;
}

.altAllDocsHeadingBase {
  margin-bottom: $token-spacing-3;
  text-transform: none;
  font-size: $token-font-size-5xl;
  font-weight: $token-font-weight-bold;
  line-height: $token-spacing-60px;
}


.container {
  position: relative;
  margin: $fullPageBanner-container-margin;
  background-color: $fullPageBanner-container-background;

  .media {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: $fullPageBanner-container-media-background;
    height: $fullPageBanner-container-media-height;
  }

  .mediaAltAllDocs {
    @extend .media;
    background-color: $token-utility-white;
  }
  
  .bannerGrid {
      background-color: $fullPageBanner-bannerGrid-background;
  
      .banner {
        margin-top: $fullPageBanner-banner-margin-top;
        padding: $fullPageBanner-banner-padding;
        background: $fullPageBanner-banner-background;
        box-shadow: $fullPageBanner-banner-box-shadow;
        border-radius: $fullPageBanner-banner-border-radius;
        border: $fullPageBanner-banner-border;
        z-index: 1;
        clear: none;
        display: inline-block;
        height: auto;
  
        .content {
          color: $token-color-grey-90;
          display: inline-grid;
          width: 100%;
          padding: $fullPageBanner-content-padding;
  
          h1 {
            margin-bottom: $token-spacing-4;
            text-transform: uppercase;
            font-size: $token-font-size-sm;
            font-weight: $fullPageBanner-h1-font-weight;
            line-height: $token-spacing-21px;
          }
  
          h2,
          .h1AsH2 {
            @extend .headingBase;

            p, b {
              @extend .headingBase;
            }
          }

          p {
            margin: $fullPageBanner-p-margin;
            font-size: $fullPageBanner-p-font-size;
            line-height: $fullPageBanner-p-line-height;
          }
  
          .buttons {
            display: inline-block;
            margin: $token-spacing-0 (
              -$token-spacing-2) (-$token-spacing-2) (-$token-spacing-2
            );
          width: 100%;
          padding-top: $token-spacing-4;
  
        }
      }
    }

    .bannerAltAllDocs {
      margin-top: $fullPageBanner-banner-margin-top;
      padding: $fullPageBanner-banner-padding;
      background: $fullPageBanner-banner-background;
      box-shadow: $fullPageBanner-banner-box-shadow;
      border-radius: 12px;
      border: $fullPageBanner-banner-border;
      z-index: 1;
      clear: none;
      display: inline-block;
      height: auto;

      .content {
        width: $token-spacing-420px;

        h1 {
          margin-bottom: $token-spacing-4;
          text-transform: uppercase;
          font-size: $token-font-size-sm;
          font-weight: $fullPageBanner-h1-font-weight;
          line-height: $token-spacing-21px;
        }

        .h1Elavon {
          @extend .altAllDocsHeadingBase;
          
          p, b {
            @extend .altAllDocsHeadingBase;
          }
        }

        p {
          margin: $token-spacing-0;
          font-size: $token-font-size-lg;
          line-height: $token-spacing-30px;
        }

        .buttons {
          display: inline-block;
          margin: $token-spacing-0 (-$token-spacing-2) (-$token-spacing-2) (-$token-spacing-2);
          width: 100%;
          padding-top: $token-spacing-4;

          .btn1 {
            background-color: $fullPageBanner-btn1-background-color;
          }
        }
      }
    }
  }
}

.containerAltAllDocs {
  @extend .container;
  margin: $token-spacing-0 $token-spacing-0 $token-spacing-12;
}

.backImg, .backImgAltAllDocs {
  min-width: $fullPageBanner-backImg-min-width;
  max-width: $fullPageBanner-backImg-max-width;
  max-height: $fullPageBanner-backImg-max-height;
  position: relative;
  width: $fullPageBanner-backImg-width;
  height: $fullPageBanner-backImg-height;
  object-fit: $fullPageBanner-backImg-object-fit;
}


@media #{$usb-min-1368} {
  .backImg {
    max-width: 100%;
    object-fit: contain;
  }

 
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  // Make the banner full width
  .container {
    padding: $fullPageBanner-container-max-lg-padding;
    margin: $fullPageBanner-container-max-lg-margin;

    .bannerGrid {
      position: $fullPageBanner-bannerGrid-position; 
      top: $fullPageBanner-bannerGrid-top; 

      .banner {
        width: 100%;
        margin-top: $fullPageBanner-banner-max-lg-margin-top;

        .content {
          h2, .h1AsH2 {
            font-size: $fullPageBanner-h2-max-sm-lg-font-size;
            line-height: $fullPageBanner-h2-max-sm-lg-line-height;
          }
        }

      }
      .contentBlock {
        width: $fullPageBanner-contentBlock-max-lg-width;
      }
    }
  }

  .containerAltAllDocs {
    .bannerGrid {
      background-color: $token-utility-white;

      .bannerAltAllDocs {
        background: $token-utility-white !important;
        margin: $token-spacing-8 $token-spacing-0;

        .content {
          width: auto;
        }
      }
    }
  }

  .backImgAltAllDocs {
    object-fit: none;
  }
}

// Smaller than MD screen size
@media #{$usb-max-md} {
  // Make the buttons full width
  .buttons {
    button {
      width: 100%;
    }
  }
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
  // Reduce the top margin for banner on Phone
  .container {
    margin: $fullPageBanner-container-margin-max-sm;
    padding: $token-spacing-0;

    .media {
      background-color: $fullPageBanner-container-background;
    }

    .bannerGrid {
      .banner {
        margin-top: $fullPageBanner-banner-max-sm-margin-top;

        .content {
          h2, .h1AsH2 {
            font-size: $fullPageBanner-h2-max-sm-lg-font-size;
            line-height: $fullPageBanner-h2-max-sm-lg-line-height;
          }

          p {
            font-size: $token-font-size-base;
          }
        }
      }
      .contentBlock {
        width: $fullPageBanner-contentBlock-max-sm-width;
      }
      background-color: $fullPageBanner-container-background;

      .bannerAltAllDocs {
        background: $token-utility-white !important;
        margin: $token-spacing-8 $token-spacing-0;

        .content {
          width: auto;
        }
      }
    }
  }

  .backImgAltAllDocs {
    object-fit: cover;
  }
}

// Bigger than LG - Desktop
@media #{$token-screen-large} {
  // Make the banner to top in the image
  .container {
    .bannerGrid {
      position: absolute !important;
      top: $token-spacing-0;
      right: $token-spacing-0;
      left: $token-spacing-0;
      margin-left: auto;
      margin-right: auto;

      .banner {
        width: $fullPageBanner-banner-width;
      }
    }
  }
}

@media (width: $token-breakpoint-large) {
  .bannerGrid {
    position: relative !important;

    .banner {
      width: 100% !important;
      margin-top: (-$token-spacing-24) !important;
    }
  }

  .banner {
    margin-top: $token-spacing-10 !important;
    padding: $token-spacing-6 !important;
    background: $token-background-primary !important;
    box-shadow: unquote($token-shadow-md) !important;
    z-index: 1 !important;
    clear: none !important;
    display: inline-block !important;
    height: auto !important;
  }
}

@media #{$token-screen-x-large} {
  .container {
    .bannerGrid {
     max-width:$token-spacing-1650px !important;
     
      .contentBlock {
        max-width: $token-spacing-1120px;
      }
    }
  }
}

@media #{$usb-max-xl} {
  .container .banner {
    margin-top: $token-spacing-20;
  }
}

@media screen and (min-width: 360px) and (max-width: $token-breakpoint-small) {
  .backImg {
    width: calc(170%);
    object-position: $fullPageBanner-backImg-sm-screen-object-position;
  }
}

@media screen and (min-width: $usb-screen-600) and (max-width: 898px) {
  .backImg {
    width: calc($fullPageBanner-backImg-screen-600-width);
    object-position: $fullPageBanner-backImg-screen-600-object-position;
  }

  .container {
    // margin: ;
    .bannerGrid {
      padding: $fullPageBanner-bannerGrid-screen-600-padding;
      .banner {
        .content {
          p {
            font-size: $token-font-size-base;
          }
        }
      }
    }
  }
}

@media (width: $usb-screen-1024) {
  .backImg {
    width: calc(150%);
    object-position: $fullPageBanner-backImg-screen-1024-object-position;
  }
}
