@import '@/styles/theme/variables.scss';
@import '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/styles/common.module.scss';

.paragraph {
  @extend .paragraph;

  padding: $token-spacing-0;
}

.fieldWrapper {
  margin-bottom: $token-spacing-5;
  legend {
    font-weight: $token-font-weight-bold;
    line-height: $token-spacing-7;
    color: $token-color-grey-90;
    div {
      display: none;
    }
  }
}

.filterText {
  font-weight: $token-font-weight-med;
  line-height: $token-spacing-7;
  color: $token-utility-black;
}

.fullWidth {
  width: 100%;
  flex-direction: row;
  position: relative;
}

.blockArrayWrapperCtaSection {
  margin-bottom: $token-spacing-16;
}

.blockArrayShortDescription {
  margin-bottom: $token-spacing-20;
}

.blockArrayWrapperCtaButton {
  margin-left:$token-spacing-0 !important;
  margin-bottom:$token-spacing-0 !important;
}

.TwelveColSubGridFix {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
 // border: $token-spacing-xs solid $token-color-divider-light-background;
 // padding: 25% 0 0 !important;
  img {
    width: $productsArrayWrapper-img-width;
    height: 100%;
  }
}
 
.imageBlock img {
  border-radius: $productsArrayWrapper-img-border-radius;
}

.imageWrapper img {
  border-radius: $token-border-radius-7px $token-border-width-0 $token-border-width-0 $token-spacing-6px; 
}
@media #{$usb-max-lg} {
  .TwelveColSubGridFix {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
  .uSBCardBottom{
    height: auto !important;
  }
}

@media #{$usb-max-md} {
  .TwelveColSubGridFix {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.responsiveColumn {
  width: 100%;
  height: 100%;
  background: $token-background-primary;
  border: $token-spacing-xs solid $token-border-base;
  border-radius: $productsArrayWrapper-border-radius;
  min-height: $token-spacing-352px;
  position: relative;
}

.uSBCardCol {
  @extend .responsiveColumn;
  //justify-content: flex-start !important;

}

.heightLayout {
  min-height: $token-spacing-256px !important;
}

.marginLayout {
  margin-bottom: $token-spacing-0 !important;
}

.textDisplayImageLeftLayout {
  order: initial;
}

.imageDisplayImageLeftLayout {
  order: initial;
}

.responsiveImageCol {
  height: 100%;
}

.imageWrapper {
  height: 100%;
}

.cardTextBody {
  height: 100%;
  margin:$token-spacing-0;
  margin-right: $token-spacing-4;
  padding-left: $token-spacing-0 !important;
  display: grid;
  grid-auto-rows: auto;
}

.linkButtonLayout {
  margin-bottom: $token-spacing-4;
}

@media screen and (min-width: $token-breakpoint-large) {
  .textDisplayImageLeftLayout {
    order: initial;
  }

  .imageDisplayImageLeftLayout {
    order: initial;
  }
}

.imageBlock {
  display: block;
  width: 100%;
  margin-bottom: $token-spacing-2;
}

.gridStyles {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  margin-bottom: $token-spacing-20 !important;
}

.uSBCardBottom {
  width: 100%;
  border-radius: $productsArrayWrapper-border-radius;
  background-color: $token-color-divider-dark-background;
  position: relative;
  box-shadow: none !important;
  border: $token-border-width-default solid $token-color-divider-light-background;
  margin: $token-spacing-4 $token-spacing-0;
  height: 256px;

  div:nth-child(2) {
    height: 100%;
    margin:$token-spacing-0;
    margin-right: $token-spacing-4;
    padding-left: $token-spacing-0 !important;
    display: grid;
    grid-auto-rows: auto;
  }
}

@media #{$usb-max-lg} {
  .gridStyles {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .blockArrayWrapperCtaButton {
    display: block;
    font-size: $token-font-size-root;
    margin: $token-spacing-0 !important;
    padding: $token-spacing-3 !important;
  }
}

@media #{$usb-max-md} {
  .gridStyles {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .blockArrayShortDescription {
    margin-bottom: $token-spacing-3;
  }
  .imageWrapper img {
    border-radius: $token-border-radius-7px $token-border-radius-7px $token-border-width-0 $token-border-width-0 !important; 
  }
}

@media #{$usb-max-425} {
  .blockArrayWrapperCtaLink {
    font-size: $token-font-size-sm !important;
  }
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
  .gridWrapper {
    padding-top: $token-spacing-0 !important;
    padding-bottom: $token-spacing-0 !important;
  }

  .uSBCardBottom{
    border-radius: $token-border-radius-7px;
  }
}
//
.block {
  margin: $token-spacing-4;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height:$token-spacing-10;
  justify-content: space-between;
  width: 90%;
  margin-top: $token-spacing-0 !important;
}

.responsiveColumnStackedBorder {
  @extend .responsiveColumn;

  min-height: $token-spacing-230px;
}

.headH3 {
  margin-top: $token-spacing-4;
}

.headH3 {
  @extend .headH3;

  position: relative;
}

.topHeadLayout {
  margin-top: $token-spacing-6 !important;
}

.marginLayout {
  margin-bottom: $token-spacing-0 !important;
}

.paragraph {
  padding-top: $token-spacing-0;
  padding-right: $token-spacing-4;
  padding-left: $token-spacing-0;
  margin-bottom: $token-spacing-8;
}

.solutionAreaLink {
  margin-bottom: $token-spacing-4;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }

  @include linkFocused;
}

.linkLayout {
  margin-right: $token-spacing-4;

  &::after {
    content: '';
    border: solid $token-background-brand-interaction;
    border-width: $token-border-width-0 $token-border-width-large $token-border-width-large $token-border-width-0;
    display: inline-block;
    padding: $token-spacing-1;
    transform: rotate(45deg);
    -webkit-transform: rotate(315deg);
    margin-left: $token-spacing-2;
    position: relative;
    top: (-$token-spacing-6);
  }
}

.topLayout::after {
  top: (-$token-spacing-2) !important;
}

.text {
  font-size: $token-font-size-base;
  font-weight: $token-font-weight-reg;
  line-height: $token-line-height-loose;
  margin-bottom: $token-spacing-8;
  height: 100%;
  min-height:$token-spacing-10;

  ul {
    @include listUl;
    padding-inline-start:$token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

.cardContainer {
  flex-direction: row !important;
  width: 100%;
  background-color: $token-color-divider-dark-background;
  border-radius: $productsArrayWrapper-border-radius;
}

// .cardContainer div:first-child {
//   flex-direction: row !important;
// }

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .headH3 {
    margin-bottom: $token-spacing-6;
  }

  .paragraph {

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}

.headH3 {
  @extend .headH3;

  font-size: $token-spacing-6;
  font-weight: $token-font-weight-med;
  line-height: $token-spacing-7;
  color: $token-color-grey-90;
  margin-bottom: $token-spacing-3;
}

@media only screen and (min-device-width: 720px) and (max-device-width: $usb-screen-1024) {

  .cardContainer div:first-child {
    border-radius: $token-border-radius-7px !important;
    flex-direction: column !important;
  }

  .uSBCardBottom{
    border-radius: $token-border-radius-7px;
  }

}

@media only screen and (max-device-width: 667px) {

  .cardContainer div:first-child {
    border-radius:  $token-border-radius-7px !important;
    flex-direction: column !important;
  }

}

@media only screen and (min-device-width: 672px) {
  .uSBCardBottom{
  
    border-radius: $token-border-radius-7px;
    
  }
}

@media #{$usb-max-lg} {
  .paragraph {
    ul {
      margin-bottom: $token-spacing-4 !important;
    }
  }

  .marketBlockLayout {
    margin-top: $token-spacing-0 !important;
  }
}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
  
  .fullWidth {
    max-width:$token-spacing-1120px !important;
  }
}

.sectionWrapper {
  width: 100% !important;
}

.clearFilterLink {
  margin-bottom: $token-spacing-4;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }

  @include linkFocused;
}
.filterHeadingText {
  display: none;
}

@media (min-width: $token-breakpoint-x-large) {
  .filterHeadingText {
    display: block;
    font-weight: $token-font-weight-med;
    font-size: $token-font-size-lg;
    color: $token-color-grey-90;
    margin-bottom: $token-spacing-4;
  }
}

.filterButtonWrapper {
  display: none !important;
}

@media (min-width: 360px) and (max-width: 1024px){ 
  .sectionWrapper {
    background-color: $token-color-grey-10 !important;
  }
  
  .filterButtonWrapper {
    display: block !important;
    width: 100% !important;

    button {
      display: block !important;
      width: 100% !important;
      margin: 0 !important;
      margin-bottom: $token-spacing-4 !important;
    }
    .filterButton img {
      margin-right: $token-spacing-2 !important;
    }
    .filterButton span {
      padding-top: $token-spacing-1 !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .columnOneWrapper {
    margin-left: $token-spacing-6 !important;
    margin-top: $token-spacing-6 !important;
  }
  .columnTwoWrapper {
    margin-top: $token-spacing-6 !important;
  }
  .columnThreeWrapper {
    margin-left: $token-spacing-6 !important;
  }
}

@media (min-width: 360px) and (max-width: 767px){
  .columnOneWrapper {
    margin-top: $token-spacing-6 !important;
    margin-left: $token-spacing-6 !important;
  }
  .columnTwoWrapper, .columnThreeWrapper {
    margin-left: $token-spacing-6 !important;
  }
}