@import '@/styles/theme/variables.scss';

// TwoColBannerImageRight view styles
.twoColBannerH1 {
  margin-bottom: $token-spacing-4;
  padding-top: $token-spacing-8;
  text-transform: uppercase;
  font-size: $token-font-size-sm;
  font-weight: $token-font-weight-med;
}

.headH2 {
  @include headH2;
  font-weight: $token-font-weight-bold;
}

.twoColBannerSubHeader {
  @extend .headH2;

  font-size: $heroBanner-twoColBannerSubHeader-font-size;
  line-height: $token-line-height-13;
}

.imageWrapper {
  width: 100%;
  margin-top: $token-spacing-8;
  overflow: hidden;

  img {
    height: $token-spacing-306px;
    width: 100%;
    max-width: none;
    object-fit: cover;
  }

}

// Smaller than LG screen size
@media #{$usb-max-lg} {

  .imageWrapper {

    img {
      height: $token-spacing-420px;
    }
  }
}

// Smaller than MD screen size
@media #{$usb-max-md} {

  .imageWrapper {

    img {
      height: $token-spacing-200px;
    }
  }
}
// End TwoColBannerImageRight view styles

// Explore AllApiHeader view styles

.exploreAllApiHeader {
  margin-top: $token-spacing-24;
  font-weight: $token-font-weight-bold;
  font-size: $token-font-size-5xl;
  line-height: $token-spacing-15;
  margin-bottom: $token-spacing-2;
}

.exploreAllApiShortDescription {
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-lg;
  line-height: $token-line-height-30px;
}

.imageWrapperExploreApi {
  width: 100%;
  position: relative;
  bottom: $token-spacing-55px;

  span img {
    min-height: $token-spacing-468px !important;
    bottom: $token-spacing-112px !important;
  }
}

// End Explore AllApiHeader view styles
.twoColBannerSection {
  background-color: transparent !important;
}

.twoColBannerImageRightContainerBackground {
  position: absolute;
  background-color: $table-row-secondary-background-color;
  width: 100%;
  height: $twoColBannerImageRightContainerBackgroundHeight !important;
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .twoColBannerRightLeftColumn {
    order: 2;
  }

  .exploreAllApiHeader {
    margin-top: $token-spacing-0;
    font-size: $token-font-size-4xl;
    line-height: $token-spacing-13;
  }

  .exploreAllApiShortDescription {
    font-size: $token-spacing-4;
    line-height: $token-spacing-6;
  }

  .exploreAllApiLeftColumn {
    order: 2;
  }

  .imageWrapperExploreApi {
    bottom: $token-spacing-0 !important;

    span img {
      min-height: $token-spacing-340px !important;
      bottom: $token-spacing-52px !important;
    }
  }

  .twoColBannerH1 {
    padding-top: $token-spacing-0;
    margin-bottom: $token-spacing-0;
  }

  .twoColBannerSubHeader {
    margin-bottom: $token-spacing-2;
  }

  .twoColBannerImageRightContainerBackground {
    position: relative;
    padding-bottom: $token-spacing-390px !important;
    margin-bottom: (-$token-spacing-405px) !important;
    display: block;
    height: 100% !important;
  }

  .twoColBannerImageRightContainer {
    background-color: inherit !important;
    margin-bottom: inherit !important;
    margin-top: (-$token-spacing-328px) !important;
  }

}

// This custom size is a fix for a small set of pixels ($token-breakpoint-medium to 750px) where percentages do not work with the hero image.
@media #{$usb-max-750} {
  .twoColBannerImageRightContainerBackground {
    padding-bottom: $token-spacing-387px !important;
    margin-bottom: (-$token-spacing-403px) !important;
  }
}

// Smaller than MD screen size
@media #{$usb-max-md} {
  .twoColBannerImageRightContainerBackground {
    padding-bottom: $token-spacing-176px !important;
    margin-bottom: $token-spacing-137px !important;
  }
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
  .twoColBannerSubHeader {
    font-size: $token-spacing-8;
    line-height: $token-spacing-10;
    margin-bottom: $token-spacing-4;
  }

  .twoColBannerH1 {
    margin-bottom: $token-spacing-6;
  }

  .exploreAllApiHeader {
    font-size: $token-spacing-8;
    line-height: $token-spacing-10;
    margin-bottom: $token-spacing-4;
  }

  .imageWrapperExploreApi {
    span img {
      min-height: $token-spacing-190px !important;
      bottom: $token-spacing-0 !important;
    }
  }

  .twoColBannerImageRightContainerBackground {
    padding-bottom: $token-spacing-180px !important;
    margin-bottom: $token-spacing-134px !important;
  }
}

@media screen and (min-width: 1180px) and (max-width: $usb-screen-1368) {
  .exploreAllApiHeader {
    margin-top: $token-spacing-8;
    font-size: $token-font-size-4xl;
  }

  .imageWrapperExploreApi {
    span img {
      min-height: $token-spacing-388px !important;
    }
  }
}
@media (min-width: $token-breakpoint-x-large) { 
  .twoColBannerImageRightContainer, .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
  .TwelveColSubGridFix {
    max-width:$token-spacing-1120px !important;
  }
}
